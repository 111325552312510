var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "truncate w-full text-left", attrs: { title: _vm.value } },
    [_vm._v(_vm._s(_vm.value || "-"))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }