<template>
  <span v-if="value === '-'" class="block w-full text-center">{{ value }}</span>
  <span v-else-if="isNaN(value) || value === null" class="block w-full text-right">N/A</span>
  <span v-else-if="removeFormatNumber" class="block w-full text-right">{{ value }}</span>
  <span v-else class="block w-full text-right">{{ value | numFormatEs }}</span>
</template>

<script>
export default {
  name: 'NumericCell',
  props: {
    value: {
      type: [Number, String, null],
      default: () => null,
    },
    removeFormatNumber: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
