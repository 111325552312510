var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value === "-"
    ? _c("span", { staticClass: "block w-full text-center" }, [
        _vm._v(_vm._s(_vm.value)),
      ])
    : isNaN(_vm.value) || _vm.value === null
    ? _c("span", { staticClass: "block w-full text-right" }, [_vm._v("N/A")])
    : _vm.removeFormatNumber
    ? _c("span", { staticClass: "block w-full text-right" }, [
        _vm._v(_vm._s(_vm.value)),
      ])
    : _c("span", { staticClass: "block w-full text-right" }, [
        _vm._v(_vm._s(_vm._f("numFormatEs")(_vm.value))),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }