<template>
  <span class="truncate w-full text-left" :title="value">{{ value || '-' }}</span>
</template>

<script>
export default {
  name: 'TextCell',
  props: {
    value: {
      type: [String, null],
      default: () => '',
    },
  },
};
</script>

<style scoped></style>
